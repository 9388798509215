import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          All the certificates issued from W3 Dev could be verified via Adobe Acrobat Reader.
        </p>
        <p>
          If there is any change in certificate verification mode, that will be updated here.
        </p>
      </header>
    </div>
  );
}

export default App;
